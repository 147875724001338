import React from 'react';
import {
    Button,
    DialogTitle,
    Typography,
    DialogContent,
    DialogActions,
    Dialog,
    TextField,
    ButtonBase,
    InputLabel,
    MenuItem,
    Snackbar,
    FormControl,
    Select,
    LinearProgress
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import firebase, { storage } from "../../config/firebase";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    button: {
        // backgroundColor: '#ffffff',
        color: '#ffffff',
        margin: theme.spacing(1),
        "&:hover": {
            color: '#000000',
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#ffffff"
        }
    },
    image: {
        position: 'relative',
        height: 200,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
    formField: {
        margin: theme.spacing(1),
    },
    formFieldSelect: {
        margin: theme.spacing(1),
        minWidth: 280,
    },

}));

const NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="RS "
        />
    );
}

export default function AddMenuItem({ categories, menu }) {
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [image, setImage] = React.useState(null);
    const [progress, setProgress] = React.useState(0);
    const classes = useStyles();
    const [values, setValues] = React.useState({
        name: '',
        description: '',
        price: '0',
        category: '',
        imageResult: null,
        image: '',
        code: '',
        imageUrl: ''
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleUploadClick = (event) => {
        var file = event.target.files[0];

        setImage(file);

        const reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setValues({
                ...values,
                imageResult: reader.result
            });
        }.bind(this);
        // 
    }

    const handleUpload = (setResult, setError) => {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on(
            "state_changed",
            snapshot => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
            },
            error => {
                setError(error)
            },
            () => {
                storage
                    .ref("images")
                    .child(image.name)
                    .getDownloadURL()
                    .then(url => {
                        // console.log('image url', url)
                        setResult(url)
                    });
            }
        );
    };

    const submit = () => {
        if (values.imageUrl.length > 3 && values.name.length > 3 && values.category.length > 4 && values.price > 75) {
            var rootRef = firebase.database().ref();
            var storesRef = rootRef.child('menu');
            var newStoreRef = storesRef.push();
            newStoreRef.set({
                name: values.name,
                price: values.price,
                catagory: values.category,
                details: values.description,
                code: values.code,
                image: values.imageUrl
            })
            handleClose()
            // handleUpload(res => {
            //     var rootRef = firebase.database().ref();
            //     var storesRef = rootRef.child('menu');
            //     var newStoreRef = storesRef.push();
            //     newStoreRef.set({
            //         name: values.name,
            //         price: values.price,
            //         catagory: values.category,
            //         details: values.description,
            //         code: values.code,
            //         image: res
            //     })
            //     handleClose()
            // }, error => {

            // })
        } else {
            setError(true)
        }
    }

    React.useEffect(() => {
        if (menu.length < 1) {
            setValues({
                ...values,
                code: 1
            })
        } else {
            const points = menu.sort(function (a, b) { return a.code - b.code });
            setValues({
                ...values,
                code: points[points.length - 1].code + 1
            })
        }
    }, [])

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<AddIcon />}
                onClick={handleClickOpen}
            >
                Menu Item
            </Button>
            <Dialog fullWidth={true} open={open} onClose={handleClose} disableBackdropClick disableEscapeKeyDown={false} aria-labelledby="form-dialog-title">
                {(progress > 0) && <LinearProgress variant="determinate" value={progress} />}
                <DialogTitle id="form-dialog-title">Add Menu Item - for item code 0{values.code}</DialogTitle>
                {error && <Alert style={{ marginLeft: 10, marginRight: 10 }} severity="error">All fields are required!</Alert>}
                <DialogContent>
                    {/* <div>
                        <label htmlFor="upload-photo">
                            <input
                                style={{ display: 'none' }}
                                accept="image/*"
                                id="upload-photo"
                                multiple
                                type="file"
                                onChange={handleUploadClick}
                            />
                            <ButtonBase
                                focusRipple
                                component="span"
                                key={'image 1'}
                                className={classes.image}
                                focusVisibleClassName={classes.focusVisible}
                                style={{
                                    width: 200,
                                }}
                            >
                                <img
                                    className={classes.imageSrc}
                                    style={{ width: '100%', objectFit: 'cover', height: '100%' }}
                                    src={values.imageResult ? values.imageResult : '/images/nofood.png'}
                                // style={{
                                //     backgroundImage: `url(${'https://material-ui.com/static/images/grid-list/breakfast.jpg'})`,
                                // }}
                                />
                                <span className={classes.imageBackdrop} />
                                <span className={classes.imageButton}>
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        color="inherit"
                                        className={classes.imageTitle}
                                    >
                                        Upload
                                    <span className={classes.imageMarked} />
                                    </Typography>
                                </span>
                            </ButtonBase>
                        </label>

                    </div> */}
                    <TextField
                        className={classes.formField}
                        autoFocus
                        margin="dense"
                        id="imageUrl"
                        name="imageUrl"
                        label="Image Url"
                        type="text"
                        onChange={handleChange}
                        fullWidth
                    />
                    <TextField
                        className={classes.formField}
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label="Food Name"
                        type="text"
                        onChange={handleChange}
                        fullWidth
                    />
                    <TextField
                        className={classes.formField}
                        margin="dense"
                        id="name"
                        name="description"
                        label="Description"
                        multiline
                        onChange={handleChange}
                        type="text"
                        fullWidth
                    />
                    <TextField
                        className={classes.formField}
                        label="Item Price"
                        value={values.numberformat}
                        onChange={handleChange}
                        name="price"
                        id="formatted-numberformat-input"
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                    />
                    <FormControl className={classes.formFieldSelect}>
                        <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.category}
                            name="category"
                            onChange={handleChange}
                        >
                            {categories.map((cat, i) => (<MenuItem key={`cat ${cat.id}`} value={cat.id}>{cat.name}</MenuItem>))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={submit} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}