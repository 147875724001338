import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Paper
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridList: {
    width: 500,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */


export default function Menu({ menu, addToCart }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {menu.map((tile, index) => (<Grid item xs={12} md={4} key={`${tile.name} - ${index}`}>
          <Paper className={classes.paper} style={{
            backgroundImage: `url(${tile.image})`,
            padding: 0,
            height: 100,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'
          }}
          onClick={() => addToCart({...tile, count: 1})}
          >

            <div style={{
              position: 'absolute',
              bottom: 0,
              backgroundColor: 'rgba(0,0,0,0.4)',
              width: '100%',
              padding: 8
            }}>
              <Typography variant="body2" color="secondary" align="left" style={{ fontWeight: '900', fontSize: 18 }}>
                Rs.{tile.price}
              </Typography>
              <Typography variant="body2" color="secondary" align="left" style={{ fontWeight: '700' }}>
                {tile.name}
              </Typography>
              {/* <Typography variant="body2" color="secondary" align="left" style={{ fontSize: 10 }}>
                {tile.details}
              </Typography> */}
            </div>
          </Paper>
        </Grid>))}
      </Grid>

      {/* <GridList cellHeight={180} className={classes.gridList}>
        {menu.map((tile, index) => (
          <GridListTile key={`${tile.image} ${index}`}>
            <img src={tile.image} alt={tile.name} />
            <GridListTileBar
              title={tile.name}
              subtitle={<span>{tile.details}</span>}
              actionIcon={
                <IconButton aria-label={`info about ${tile.name}`} className={classes.icon}>
                  <Typography>
                     Rs {tile.price}
                  </Typography>
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList> */}
    </div>
  );
}