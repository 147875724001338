import React from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Typography,
  MenuItem,
  Select,
  Box,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import firebase from "../../config/firebase";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#e67e22",
    color: "#ffffff",
    margin: theme.spacing(1),
    "&:hover": {
      color: "#000000",
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#ffffff",
    },
  },
  formField: {
    margin: theme.spacing(1),
  },
  formFieldSelect: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
  autocomplete: {
    marginRight: theme.spacing(1),
  },
}));

export default function Offers({ menuData }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [currentMenu, setCurrentMenu] = React.useState([]);
  const [selectedMenu, setSelectedMenu] = React.useState([]);
  const [values, setValues] = React.useState({
    name: "",
    for: [],
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().add(1, "day").format("YYYY-MM-DD"),
  });

  const handleDateChange = (name, date) => {
    setValues({
      ...values,
      [name]: moment(date).format("YYYY-MM-DD"),
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleOfferChange = (prop, value) => {
    let valveObj = { ...values };
    valveObj.for.map((x) => {
      if (x.id == prop) {
        x.discount = value
      }
    });

    setValues(valveObj);
  };

  const addedToList = () => {
    setValues((x) => ({ ...x, for: selectedMenu }));
  };

  const submit = (s) => {
    var rootRef = firebase.database().ref();
    var storesRef = rootRef.child("offers");
    var newStoreRef = storesRef.push();
    newStoreRef.set(values);
    handleClose();
  };

  React.useEffect(() => {
    setCurrentMenu(menuData);
  });

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        Offers
      </Button>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown={false}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Offers</DialogTitle>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DialogContent>
            <TextField
              className={classes.formField}
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label="Discount Name"
              type="text"
              onChange={handleChange}
              fullWidth
            />
            <KeyboardDatePicker
              className={classes.formField}
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline1"
              label="Offer Start Date"
              value={values.startDate}
              onChange={(date) => handleDateChange("startDate", date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardDatePicker
              className={classes.formField}
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline2"
              label="Offer End Date"
              value={values.endDate}
              onChange={(date) => handleDateChange("endDate", date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              mt={3}
              mb={3}
            >
              <Autocomplete
                multiple
                fullWidth
                id="tags-outlined"
                options={currentMenu}
                getOptionLabel={(option) => option.name}
                value={selectedMenu}
                className={classes.autocomplete}
                onChange={(event, newValue) => {
                  setSelectedMenu(newValue);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Food Items"
                    placeholder="Add Item"
                  />
                )}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonAdd}
                onClick={addedToList}
              >
                Add
              </Button>
            </Box>
            <div style={{ backgroundColor: "#f0f0f0" }}>
              {values.for.map((_offer, index) => {
                return (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    m={1}
                    key={_offer.id}
                  >
                    <Typography
                      style={{
                        width: 280,
                      }}
                    >
                      {_offer.name}
                    </Typography>
                    <Typography
                      style={{
                        width: 60,
                      }}
                    >
                      {_offer.discount
                        ? Math.round(_offer.price * ((100 - _offer.discount)/100))
                        : _offer.price}
                    </Typography>
                    <Select
                      labelId="disc-simple-select-label"
                      id="disc-simple-select"
                      value={_offer.discount|| ''}
                      style={{ width: 80 }}
                      onChange={event => handleOfferChange(_offer.id, event.target.value)}
                    >
                      <MenuItem value={10}>10%</MenuItem>
                      <MenuItem value={20}>20%</MenuItem>
                      <MenuItem value={30}>30%</MenuItem>
                      <MenuItem value={40}>40%</MenuItem>
                      <MenuItem value={50}>50%</MenuItem>
                    </Select>
                  </Box>
                );
              })}
            </div>
          </DialogContent>
        </MuiPickersUtilsProvider>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submit} color="primary" disabled={!values.name || values.for.length < 1}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
