import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { Home, Oparation, Login, Register } from './views';
import { AuthProvider } from './context/Auth'
import PrivateRoute from "./components/PrivateRoute";

export default function App() {

  return (
    <AuthProvider>
      <Router>
        <Switch>
          <PrivateRoute exact path="/op" component={Oparation} />
          <PrivateRoute exact path="/signup" component={Register} />
          <Route path="/login" component={Login} />
          <Route  path="/" component={Home} />
          <Route exact path="/menu" component={Home} />
          <Redirect to={'/'}/>
        </Switch>
      </Router>
    </AuthProvider>
  );
}