import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  Grid,
  LinearProgress,
  Typography,
  IconButton,
  Divider,
  MenuItem,
  Select,
  Button,
  FormGroup,
  FormControlLabel,
  FormControl,
  Switch,
  InputLabel,
  Input,
} from "@material-ui/core";
import clsx from "clsx";
import { yellow, orange, purple } from "@material-ui/core/colors";
import AddIcon from "@material-ui/icons/Add";
import shortid from "shortid-36";
import RemoveIcon from "@material-ui/icons/Remove";
import Menu from "./menu";
import AddMenuItem from "./AddMenuItem";
import AddCategory from "./AddCategory";
import Offers from "./Offers";
import Logout from "./Logout";
import firebase from "../../config/firebase";
import OperationLayout from "./OperationLayout";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "center",
    backgroundColor: "#ffffff",
  },
  button: {
    backgroundColor: "#000000",
    color: "#ffffff",
    margin: theme.spacing(1),
    "&:hover": {
      color: "#000000",
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#dddddd",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "15ch",
  },
}));

const PickMeSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: yellow[500],
    },
    "&$checked + $track": {
      backgroundColor: yellow[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const TableSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: orange[500],
    },
    "&$checked + $track": {
      backgroundColor: orange[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const PickUpSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: purple[500],
    },
    "&$checked + $track": {
      backgroundColor: purple[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const getOrderType = (data) => {
  if (data.checkedUber) {
    return "UBER";
  }
  if (data.checkedPickme) {
    return "PICK_ME";
  }
  if (data.checkedTable) {
    return "BAKER_STREET_TABLE";
  }

  if (data.checkedPickup) {
    return "SELF_PICK_UP";
  }

  return "FAULT_ORDER";
};

export default function Main() {
  const classes = useStyles();
  const [categories, setCategory] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [discount, setDiscount] = React.useState(0);
  const [tableNo, setTableNo] = React.useState(0);
  const [preparationTime, setPreparationTime] = React.useState(20);
  const [orderId, setOrderId] = React.useState(shortid.generate());
  const [menu, setMenu] = React.useState([]);
  const [cart, setCart] = React.useState([]);
  const [state, setState] = React.useState({
    checkedUber: false,
    checkedPickme: false,
    checkedTable: false,
    checkedPickup: false,
  });

  const addToCart = (food) => {
    if (cart.findIndex((x) => x.id == food.id) < 0) {
      setCart((prevState) => [...prevState, food]);
    }
  };

  const itemCountChange = (count, food) => {
    let cartOld = [...cart];
    if (count < 1) {
      cartOld = cart.filter((x) => x.id != food.id);
    } else {
      cart.map((x) => {
        if (food.id == x.id) {
          x.count = count;
        }
      });
    }

    setCart(cartOld);
  };

  const total = (withoutDis) => {
    let price = 0;
    cart.map((x) => {
      price += Number(x.price) * x.count;
    });

    if (withoutDis) {
      return Math.round(price).toFixed(2);
    }

    if (discount) {
      price = price * ((100 - discount) / 100);
    }

    return Math.round(price).toFixed(2);
  };

  React.useEffect(() => {
    setLoading(true);
    firebase
      .database()
      .ref("category")
      .on("child_added", (val) => {
        const category = val.val();
        setCategory((prevState) => [
          ...prevState,
          { ...category, id: val.key },
        ]);
      });

    firebase
      .database()
      .ref("menu")
      .on("child_added", (val) => {
        const menu = val.val();
        setMenu((prevState) => [...prevState, { ...menu, id: val.key }]);
        setLoading(false);
      });
  }, []);

  const handleChange = (event) => {
    setState({
      checkedUber: false,
      checkedPickme: false,
      checkedTable: false,
      checkedPickup: false,
      [event.target.name]: event.target.checked,
    });
    if (
      event.target.name == "checkedUber" ||
      event.target.name == "checkedPickme"
    ) {
      setOrderId("");
    }
  };

  const complateOrder = () => {
    const user = firebase.auth().currentUser;
    const orderObject = {
      items: cart,
      orderTimeLocal: moment().format("DD-MM-YYYYTHH:mm"),
      type: getOrderType(state),
      prepTime: preparationTime,
      timeUnit: "minute",
      orderedBy: user.uid,
      tableNo: tableNo,
      orderId: orderId,
      discount: discount,
      totalPriceWithDiscount: total(),
      totalWithoutPrice: total(true),
      currency: "LKR",
      status: "PENDING",
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    };

    const orderRef = firebase.database().ref(`orders/${moment().format("DD-MM-YYYY")}`);
    orderRef.push(orderObject).then((snap) => {
      const key = snap.key;
      firebase.database().ref(`orders/${moment().format("DD-MM-YYYY")}/${key}`).update({
        id: key,
      });
    });

    setOrderId(shortid.generate());
    setDiscount(0);
    setState({
      checkedUber: false,
      checkedPickme: false,
      checkedTable: false,
      checkedPickup: false,
    });
    setCart([]);
  };

  const validateOrderButton = () => {
    if ((state.checkedUber || state.checkedPickme) && orderId.length > 3) {
      return false;
    } else if (state.checkedTable && tableNo > 0) {
      return false;
    } else if (state.checkedPickup) {
      return false;
    }

    return true;
  };

  return (
    <OperationLayout>
      {loading && <LinearProgress />}
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper
              className={classes.paper}
              style={{ maxHeight: 960, overflow: "auto" }}
              elevation={0}
            >
              <Menu menu={menu} addToCart={(food) => addToCart(food)} />
            </Paper>
            <Box display="flex" p={1}>
              <AddMenuItem categories={categories} menu={menu} />
              <AddCategory categories={categories} />
              <Offers menuData={menu} />
              <Logout />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              {cart.length > 0 && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  p={1}
                  bgcolor={"#dddddd"}
                >
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <PickMeSwitch
                          checked={state.checkedPickme}
                          onChange={handleChange}
                          name="checkedPickme"
                          className={classes.pickme}
                        />
                      }
                      label={"Pick Me"}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.checkedUber}
                          onChange={handleChange}
                          name="checkedUber"
                          color="primary"
                        />
                      }
                      label={"Uber"}
                    />
                    <FormControlLabel
                      control={
                        <TableSwitch
                          checked={state.checkedTable}
                          onChange={handleChange}
                          name="checkedTable"
                        />
                      }
                      label={"Table"}
                    />
                    {state.checkedTable && (
                      <FormControl
                        className={classes.formControl}
                        style={{ marginRight: 10 }}
                      >
                        <Select
                          labelId="disc-simple-select-label order"
                          id="disc-simple-select time"
                          value={tableNo || 0}
                          style={{ width: 100 }}
                          onChange={(event) => setTableNo(event.target.value)}
                          placeholder={"Time"}
                        >
                          <MenuItem value={0}>No table</MenuItem>
                          <MenuItem value={1}>1 table</MenuItem>
                          <MenuItem value={2}>2 table</MenuItem>
                          <MenuItem value={3}>3 table</MenuItem>
                          <MenuItem value={4}>4 table</MenuItem>
                          <MenuItem value={5}>5 table</MenuItem>
                          <MenuItem value={6}>5 table</MenuItem>
                          <MenuItem value={7}>5 table</MenuItem>
                          <MenuItem value={8}>5 table</MenuItem>
                          <MenuItem value={9}>5 table</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    <FormControlLabel
                      control={
                        <PickUpSwitch
                          checked={state.checkedPickup}
                          onChange={handleChange}
                          name="checkedPickup"
                        />
                      }
                      label={"Self Pickup"}
                    />
                  </FormGroup>
                </Box>
              )}
              {cart.length > 0 && (
                <Box>
                  {cart.map((tile, index) => (
                    <Box
                      key={tile.id}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      m={1}
                      p={1}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <img
                          src={tile.image}
                          style={{
                            height: 50,
                            width: 50,
                          }}
                        />
                        <Typography
                          style={{
                            marginLeft: 16,
                            width: 280,
                            textAlign: "left",
                          }}
                        >
                          {tile.name}
                        </Typography>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          ml={2}
                        >
                          <IconButton
                            aria-label="add"
                            className={classes.margin}
                            onClick={() =>
                              itemCountChange(tile.count - 1, tile)
                            }
                          >
                            <RemoveIcon fontSize="small" />
                          </IconButton>
                          <Typography
                            style={{ marginLeft: 10, marginRight: 10 }}
                          >
                            {tile.count}
                          </Typography>
                          <IconButton
                            aria-label="add"
                            className={classes.margin}
                            onClick={() =>
                              itemCountChange(tile.count + 1, tile)
                            }
                          >
                            <AddIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                      <div>
                        <Typography style={{ fontSize: "600" }}>
                          {tile.price}
                        </Typography>
                      </div>
                    </Box>
                  ))}
                </Box>
              )}
              <Divider />
              {cart.length > 0 && (
                <Box
                  p={2}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography>Total</Typography>
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-simple-select-label">
                      Discount
                    </InputLabel> */}
                    <Select
                      labelId="disc-simple-select-label"
                      id="disc-simple-select"
                      value={discount || 0}
                      style={{ width: 80 }}
                      onChange={(event) => setDiscount(event.target.value)}
                      placeholder={"Discount"}
                    >
                      <MenuItem value={0}>0%</MenuItem>
                      <MenuItem value={10}>10%</MenuItem>
                      <MenuItem value={20}>20%</MenuItem>
                      <MenuItem value={30}>30%</MenuItem>
                      <MenuItem value={40}>40%</MenuItem>
                      <MenuItem value={50}>50%</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                    style={{ marginRight: 10 }}
                  >
                    <Select
                      labelId="disc-simple-select-label order"
                      id="disc-simple-select time"
                      value={preparationTime || 20}
                      style={{ width: 80 }}
                      onChange={(event) =>
                        setPreparationTime(event.target.value)
                      }
                      placeholder={"Time"}
                    >
                      <MenuItem value={20}>20 min</MenuItem>
                      <MenuItem value={25}>25 min</MenuItem>
                      <MenuItem value={30}>30 min</MenuItem>
                      <MenuItem value={35}>35 min</MenuItem>
                      <MenuItem value={40}>40 min</MenuItem>
                      <MenuItem value={45}>45 min</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl className={clsx(classes.textField)}>
                    <Input
                      id="standard-adornment-orderId"
                      value={orderId}
                      onChange={(event) => setOrderId(event.target.value)}
                      aria-describedby="standard-orderId-helper-text"
                      inputProps={{
                        "aria-label": "orderId",
                      }}
                    />
                  </FormControl>
                  <Typography style={{ fontSize: 18, fontWeight: "600" }}>
                    Rs. {total()}
                  </Typography>
                </Box>
              )}
              {cart.length < 1 && (
                <Box
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pb={3}
                  textAlign="center"
                  bgcolor={"#dddddd"}
                >
                  <img
                    style={{
                      height: 100,
                      width: 100,
                      marginBottom: 20,
                    }}
                    src="images/emptycart.png"
                  />
                  <Typography variant="h6">Add Menu Item To Cart</Typography>
                  <Typography variant="subtitle2">
                    You’re not picky. You just have discerning taste.
                  </Typography>
                </Box>
              )}
              {cart.length > 0 && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  mr={2}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={complateOrder}
                    disabled={validateOrderButton()}
                  >
                    Order
                  </Button>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </OperationLayout>
  );
}
