import React from 'react';
import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    DialogContentText,
} from '@material-ui/core';
import firebase from "../../config/firebase";
import { makeStyles } from '@material-ui/core/styles';
import ExitToApp from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
    button: {
        // backgroundColor: '#ffffff',
        color: '#ffffff',
        margin: theme.spacing(1),
        "&:hover": {
            color: '#000000',
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#ffffff"
        }
    },
    formField: {
        margin: theme.spacing(1),
    },
    formFieldSelect: {
        margin: theme.spacing(1),
        minWidth: 280,
    },

}));

export default function AddCategory() {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<ExitToApp />}
                onClick={() => setOpen(true)}
            >
                Log Out
            </Button>
            <Dialog fullWidth={true} open={open} onClose={() => setOpen(false)} disableBackdropClick disableEscapeKeyDown={false} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Are You Sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>You will be navigate to the home screen</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        firebase.auth().signOut()
                        setOpen(false)
                        }} color="error">
                        Sign Out
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}