const environment = {
    development: {
        v: '1.0.0',
        firebaseConfig: {
            apiKey: "AIzaSyAruQq8omahncCClzSBuRsymBVup1MxIB8",
            authDomain: "bakerstreet-c5d82.firebaseapp.com",
            databaseURL: "https://bakerstreet-c5d82.firebaseio.com",
            projectId: "bakerstreet-c5d82",
            storageBucket: "bakerstreet-c5d82.appspot.com",
            messagingSenderId: "11871674108",
            appId: "1:11871674108:web:60f448a835badeb50cc179",
            measurementId: "G-84L20QWEYB"
        }
    },
    production: {
        v: '1.0.0',
        firebaseConfig: {
            apiKey: "AIzaSyAruQq8omahncCClzSBuRsymBVup1MxIB8",
            authDomain: "bakerstreet-c5d82.firebaseapp.com",
            databaseURL: "https://bakerstreet-c5d82.firebaseio.com",
            projectId: "bakerstreet-c5d82",
            storageBucket: "bakerstreet-c5d82.appspot.com",
            messagingSenderId: "11871674108",
            appId: "1:11871674108:web:60f448a835badeb50cc179",
            measurementId: "G-84L20QWEYB"
        }
    },
}



export default environment[process.env.NODE_ENV]