import React from 'react';
import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    TextField,
} from '@material-ui/core';
import firebase from "../../config/firebase";
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    button: {
        // backgroundColor: '#ffffff',
        color: '#ffffff',
        margin: theme.spacing(1),
        "&:hover": {
            color: '#000000',
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#ffffff"
        }
    },
    formField: {
        margin: theme.spacing(1),
    },
    formFieldSelect: {
        margin: theme.spacing(1),
        minWidth: 280,
    },

}));

export default function AddCategory() {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const [values, setValues] = React.useState({
        name: '',
        description: '',
        key: '',
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const submit = (s) => {
        var rootRef = firebase.database().ref();
        var storesRef = rootRef.child('category');
        var newStoreRef = storesRef.push();
        newStoreRef.set(values)
        handleClose()
    }

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<AddIcon />}
                onClick={handleClickOpen}
            >
                Category
            </Button>
            <Dialog fullWidth={true} open={open} onClose={handleClose} disableBackdropClick disableEscapeKeyDown={false} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Category</DialogTitle>
                <DialogContent>
                    <TextField
                        className={classes.formField}
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label="Food Name"
                        type="text"
                        onChange={handleChange}
                        fullWidth
                    />
                    <TextField
                        className={classes.formField}
                        margin="dense"
                        id="key"
                        name="key"
                        label="Key"
                        multiline
                        onChange={handleChange}
                        type="text"
                        fullWidth
                    />
                    <TextField
                        className={classes.formField}
                        margin="dense"
                        id="description"
                        name="description"
                        label="Description"
                        multiline
                        onChange={handleChange}
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={submit} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}