import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, fade } from '@material-ui/core/styles';
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  Box,
  Button,
  IconButton,
  Divider,
  Typography,
  Toolbar,
  AppBar,
  CssBaseline,
  Drawer,
  useMediaQuery,
  Container,
  InputBase,
  Link
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AddShoppingCartIconIcon from '@material-ui/icons/ShoppingCart';
import ViewContainer from './ViewContainer';

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#ffffff'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.secondary.main, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.main, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  buttonLocationAdd: {
    position: 'absolute',
    // right: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%)',
    // margin: theme.spacing(1),
    padding: theme.spacing(1, 4),
    borderRadius: 20,
    backgroundColor: '#dddddd',
    fontSize: 12
  },
  timerange: {
    position: 'absolute',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%)',
    // margin: theme.spacing(1),
    padding: theme.spacing(1, 4)
  },
  logoText: {
    color: theme.palette.app.main,
    fontWeight: 'bold'
  },
  logoTextSecond: {
    color: '#000000'
  },
  footer: {
    padding: theme.spacing(3, 2),
    // position: 'absolute',
    // width: '100%',
    // bottom: 0,
    backgroundColor: '#333333',
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[900] : theme.palette.grey[100],
  },
}));


function Copyright() {
  return (
    <Typography variant="body2" color="secondary">
      {'Copyright © '}
      <Link color="secondary" href="/">
        Voyox (Pvt) Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [locationModal, setLocationModal] = React.useState(false);
  const matches = useMediaQuery("(max-width:600px)");

  const handleLocationModalOpen = () => {
    setLocationModal(true);
  };

  const handleLocationModalClose = () => {
    setLocationModal(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          elevation={0}
        >
          <Toolbar>
            {/* <Button
            color="default"
            className={classes.buttonLocationAdd}
            startIcon={<LocationOnIcon />}
            onClick={handleLocationModalOpen}
          >
            Add Your Location
          </Button> */}
            <Box>
              <Typography variant="h5" noWrap className={classes.logoText}>
                BAKER ST
                  <Typography component="span" variant="h5" className={classes.logoTextSecond} noWrap>
                      REET
                  </Typography>
              </Typography>
              {matches && "11:00 AM to 10:00 PM"}
            </Box>
            {!matches && <Typography variant="h6" className={classes.timerange} align="center">
              11:00 AM to 10:00 PM
                <Typography style={{ color: '#333333', fontSize: 14 }}>BEST FOOD OFFERS IN COLOMBO</Typography>
            </Typography>}
            <Box
              display="flex"
              flexGrow={1}
            />
            {/* {matches && <Typography variant="subtitle1" align="right" style={{ color: '#000000', fontSize: 10 }}>
              11:00 AM to 11:00 PM
                <Typography variant="subtitle1" align="right" style={{ color: '#333333', fontSize: 10 }}>Find us on UberEats PickMeFoods</Typography>
            </Typography>} */}
            {!matches && <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="What are you craving?"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <AddShoppingCartIconIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            m={3}
            mb={10}
            textAlign="center"
          >
            <img
              style={{
                height: 200,
                width: 200,
                marginBottom: 20
              }}
              src="images/emptycart.png"
            />
            <Typography variant="h6">
              Coming Soon!
            </Typography>
            <Typography variant="subtitle2">
              You’re not picky. You just have discerning taste.
            </Typography>
          </Box>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <ViewContainer />
        </main>
        <Dialog
          open={locationModal}
          onClose={handleLocationModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Let Google help apps determine location. This means sending anonymous location data to
              Google, even when no apps are running.
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLocationModalClose} color="primary">
              Disagree
          </Button>
            <Button onClick={handleLocationModalClose} color="primary" autoFocus>
              Agree
          </Button>
          </DialogActions>
        </Dialog>
      </div>
      <footer className={classes.footer}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography color="secondary" variant="body1">Baker Street, Food you never tasted before.</Typography>
            <Typography color="secondary" variant="h6" style={{ fontWeight: 'bold' }}>Order Now (011) 281 8244</Typography>
            <Typography color="secondary" variant="body2">DIRECT ORDERS ARE WELCOME GET EXTRA DISCOUNT WITH SPECIAL ORDERS</Typography>
            <Typography variant="body2" color="secondary">
              <Link style={{ color: '#f1c40f', fontWeight: '900', marginRight: 10 }} href="https://a.pickme.lk/j0SHcnc2scb">
                PickMe
              </Link>
              |
              <Link style={{ color: '#27ae60', fontWeight: '900', marginLeft: 10 }} href="https://tinyurl.com/yckem6xe">
                Uber
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Typography color="secondary" variant="subtitle1" style={{ fontWeight: 'bold' }}>
                BAKER STREET
            </Typography>
              <Typography color="secondary" variant="subtitle1">
                137/2, S De S Jayasinghe Mawatha,
            </Typography>
              <Typography color="secondary" variant="subtitle1">
                Kohuwala, Nugegoda.
            </Typography>
              <Copyright />
            </Box>
          </Grid>
        </Grid>
      </footer>
    </div>
  );
}