import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Grid,
    useMediaQuery,
    Typography,
    Box,
    Tab,
    Tabs,
    Link,
    Button
} from '@material-ui/core';
import {
    useLocation,
    useHistory
} from "react-router-dom";
import clsx from 'clsx';
import DirectionsIcon from '@material-ui/icons/Directions';
import ViewFoodDetails from './ViewFoodDetails';
import firebase from '../../config/firebase';
import cover_image from '../../images/cover_image.jpeg'
import ubericon from '../../images/ubericon.png'
import pickmeicon from '../../images/pickmeicon.png'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    paper: {
        color: theme.palette.text.secondary,
    },
    directionButton: {
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#27ae60"
        }
    },
    labelContainer: {
        overflow: 'hidden',
        position: 'relative',
    },
    span: {
        display: 'block',
        width: 120,
        height: 24,
        backgroundColor: 'red',
        '-ms-transform': 'rotate(45deg)',
        '-webkit-transform': 'rotate(45deg)',
        transform: 'rotate(45deg)',
        position: 'absolute',
        textAlign: 'center',
        // lineHeight: 24,
        right: -26,
        top: 22,
        fontSize: 15,
        color: '#ffffff'
    }
}));

// const CatagoryList = ['All', 'Submarine', 'Hotdogs', 'Wraps & Quesadillas', 'Burger']

function snapshotToArray(snapshot) {
    var returnArr = [];
    Object.keys(snapshot).forEach(function (childSnapshot) {
        returnArr.push({
            ...snapshot[childSnapshot],
            id: childSnapshot
        })
    });

    return returnArr;
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function CenteredGrid() {
    const classes = useStyles();
    const matches = useMediaQuery("(max-width:600px)");
    const [value, setValue] = React.useState(0);
    const [fromMenu, setFromMenu] = React.useState(false);
    const [menu, setMenu] = React.useState([])
    const [details, setDetails] = React.useState({})
    const [open, setOpen] = React.useState(false);
    const [catagoryList, setCatagoryList] = React.useState([])
    let location = useLocation();
    const messagesEndRef = useRef(null)
    let query = useQuery();
    let history = useHistory();

    const handleChange = (event, newValue) => {
        if (catagoryList[newValue] && catagoryList[newValue].name) {
            history.push(`/menu?type=${catagoryList[newValue].name}`);
        }
        setValue(newValue);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const selectFoodItem = (data) => {
        setDetails(data)
        setOpen(true)
    }

    React.useEffect(() => {
        firebase.database().ref('menu').on('value', (snapshot) => {
            setMenu(snapshotToArray(snapshot.val()))
        });

        firebase.database().ref('category').on('value', (snapshot) => {
            setCatagoryList([{ id: 'ALL', name: 'ALL' }, ...snapshotToArray(snapshot.val())])
            setTimeout(() => {
                if (String(location.pathname).includes('menu')) {
                    scrollToBottom()
                    setFromMenu(true)
                }
            }, 100);

            let catarray = {}

            Object.keys(snapshot.val()).map((itemKey, i) => {
                catarray[snapshot.val()[itemKey].name] = i + 1
            })

            if (String(query.get("type")).toLocaleLowerCase() == 'all') {
                setValue(0)
            }
            if (String(query.get("type")).toLocaleLowerCase() == 'wraps and quesadillas') {
                setValue(catarray['Wraps and Quesadillas'])
            }

            if (String(query.get("type")).toLocaleLowerCase() == "burgers") {
                setValue(catarray["Burgers"])
            }

            if (String(query.get("type")).toLocaleLowerCase() == "submarines") {
                setValue(catarray["Submarines"])
            }

            if (String(query.get("type")).toLocaleLowerCase() == "fried rice") {
                setValue(catarray["Fried Rice"])
            }

            if (String(query.get("type")).toLocaleLowerCase() == "yellow rice") {
                setValue(catarray["Yellow Rice"])
            }
        });
    }, [])

    return (
        <div>
            <ViewFoodDetails
                open={open}
                setOpen={setOpen}
                details={details}
            />
            <div
                style={{
                    backgroundImage: `url(${cover_image})`,
                    width: '100%',
                    height: 300,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative'
                }}
                className={classes.labelContainer}
            >
                <span className={classes.span}>CALL US OF MORE OFFERS</span>
                <div style={{

                }} />
                <div style={{
                    position: 'absolute',
                    left: 40,
                    bottom: 20
                }}
                >
                    <Typography variant="h4" style={{ color: '#ffffff' }}>
                        BEST OFFERS FOR SELF PICK-UP
                    </Typography>
                    {/* <Typography variant="h3" style={{ color: 'yellow' }}>
                        30% OFF
                    </Typography> */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        position="relative"
                    >
                        <div
                            style={{
                                position: 'absolute',
                                height: '100%',
                                width: 140,
                                backgroundColor: '#ffffff',
                                opacity: 0.3,
                                zIndex: 0,
                                borderRadius: 10
                            }}
                        />
                        <img
                            src={pickmeicon}
                            style={{
                                width: 60,
                                height: 60,
                                margin: 5,
                                zIndex: 1,
                                cursor: 'pointer'
                            }}
                            onClick={() => window.open("https://a.pickme.lk/j0SHcnc2scb", "_blank")}
                        />
                        <img
                            src={ubericon}
                            style={{
                                width: 60,
                                height: 60,
                                margin: 5,
                                zIndex: 1,
                                cursor: 'pointer'
                            }}
                            onClick={() => window.open("https://tinyurl.com/yckem6xe", "_blank")}
                        />
                    </Box>
                    <Typography component="span" variant="body1" style={{ color: '#ffffff', fontSize: 10 }}>Find us from uber eats and pickme for Order</Typography>
                    <Typography variant="h5" style={{ color: '#ffffff' }}>
                        We’re out to win you over
                    </Typography>
                    {matches && <Button
                        variant="contained"
                        color="secondary"
                        className={classes.directionButton}
                        onClick={() => window.open("https://g.page/Bakerstreet-nugegoda?gm", "_blank")}
                        endIcon={<DirectionsIcon />}
                    >
                        Get Direction
                    </Button>}
                </div>
                <div style={{
                    position: 'absolute',
                    right: 40,
                    bottom: 20,
                    display: matches ? 'none' : 'block'
                }}>
                    <Typography variant="h6" style={{ color: '#ffffff' }}>
                        Baker Street
                    </Typography>
                    <Typography variant="h5" style={{ color: '#ffffff', fontWeight: 'bold' }}>
                        HotLine: (011) 281 8244
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.directionButton}
                        onClick={() => window.open("https://g.page/Bakerstreet-nugegoda?gm", "_blank")}
                        endIcon={<DirectionsIcon />}
                    >
                        Get Direction
                    </Button>
                </div>
            </div>
            <div
                style={{
                    width: 100,
                    height: fromMenu ? 90 : 0
                }}
                ref={messagesEndRef}
            />
            <Paper elevation={0}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                    style={{ width: matches ? 380 : null }}
                >
                    {catagoryList.map((cat) => (
                        <Tab key={cat.id} label={cat.name} />
                    ))}
                </Tabs>
            </Paper>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    {menu.map((food, i) => {
                        if (value != 0 && catagoryList[value].id != food.catagory) {
                            return null
                        }

                        return (<Grid
                            key={food.id}
                            item xs={12} md={4}>
                            <Paper
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => selectFoodItem(food)}
                                className={classes.paper}>
                                <Box
                                    display="flex"
                                >
                                    <Box
                                        display="flex"
                                        flex={1}
                                        flexDirection="column"
                                        alignItems="flex-start"
                                        pl={2}
                                        pt={1}
                                        pb={1}
                                    >
                                        <Typography style={{
                                            fontSize: 16,
                                            fontWeight: '500',
                                            color: '#000000'
                                        }}>{food.name}</Typography>
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                textAlign: 'left',
                                                fontSize: 12,
                                                marginBottom: 10
                                            }}
                                        >{food.details}</Typography>
                                        <Typography variant="body1">LKR {food.price}</Typography>
                                    </Box>
                                    <div
                                        style={{
                                            backgroundImage: `url(${food.image})`,
                                            width: 140,
                                            height: 140,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            position: 'relative'
                                        }}
                                        className={classes.labelContainer}
                                    >
                                        {food.offer && <span className={classes.span}>{food.offer}</span>}
                                    </div>
                                </Box>
                            </Paper>
                        </Grid>)
                    })}
                </Grid>
            </div>
        </div>
    );
}