import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#2ecc71',
      main: '#27ae60',
      dark: '#20874c',
      contrastText: '#000000',
    },
    secondary: {
      light: '#2ecc71',
      main: '#ffffff',
      dark: '#338053',
      contrastText: '#000',
    },
    app: {
      main: '#f39c12',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff'
    },
  },
  mixins: {
    toolbar: {
      minHeight: 90
    }
  }
});

export default theme;
