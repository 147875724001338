import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function CustomizedDialogs({ open, setOpen, details }) {
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    console.log('details ', details)

    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogContent dividers style={{ padding: 0, borderTop: 0 }}>
                    <div style={{
                        position: 'absolute',
                        top: 20,
                        left: 10,
                        zIndex: 999999,
                        backgroundColor: '#000000',
                        padding: 10
                    }}>
                        <Typography 
                        variant={'h5'}
                        style={{
                            color: '#ffffff',
                        }}>
                           {details.name}
                        </Typography>
                    </div>
                    <img
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain'
                        }}
                        src={details.image}
                    />
                    <div style={{
                        padding: 16
                    }}>
                        <Typography gutterBottom>
                            {details.details}
                        </Typography>
                        <div style={{
                            backgroundColor: 'orange',
                            padding: 10,
                            width: 120,
                            borderRadius: 2,
                            color: '#ffffff'
                        }}>
                            <Typography variant="h6">
                                Rs. {details.price}/=
                            </Typography>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}